@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
@import 'bootstrap.min.css';
/* @import 'custom-bootstrap'; */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
.theme-switch-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 20px;
}

.theme-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.theme-switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transition: .4s;
  border-radius: 34px;
  border: 1px solid var(--line);
}

.slider:before {
  position: absolute;
  content: "🌞";
  color: yellow;
  height: 25px;
  width: 25px;
  left: 4px;
  bottom: 4px;
  transition: .4s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(25px);
  content: "🌜";
  color: #fff;
}

body.light {
  --primary-background:#ffffff;
  --secondary-background:#caf0f8;
  --main:#0076E8;
  --primary-text:#0077b6;
  --secondary-text:#003a8c;
  --tertiary-text: #000b1a;
  --main-text:#ffb703;
  --line:rgba(25, 25, 39, 0.5); 
  --nav: #ffffff;
}

body.dark {
  --primary-background:#171933;
  --secondary-background:#191C3B;
  --main:#0076E8;
  --primary-text:#0077b6;
  --secondary-text:#ffffff;
  --tertiary-text:#ffffff;
  --main-text:#ffffff;
  --line:rgba(228, 228, 231, 0.5); 
  --nav: #01456a;
}


body{
  background-color: var(--primary-background);
}

/* navbar starts here  */
nav{
  display: flex;
  justify-content: space-between;
  padding: 0.95% 9.5%;
  background-color: var(--nav);
  align-items: center;
  transition: all 0.4s ease-in-out;
  z-index: 1000;
  box-shadow: 0px 2px 4px rgba(38, 38, 39, 0.2);
}
nav.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.nav-logo{
  display: flex;
  color: var(--secondary-text);
  align-items: center;
  font-size: 20px;
  font-family: "Ubuntu", sans-serif;
  text-decoration: none !important;
}
.nav-logo img{
  height: 70px;
  margin-right: 15px;
}
.nav-logo p {
  text-decoration: none; /* Remove underline */
}

.pages a{
  text-decoration: none;
  color: var(--secondary-text);
  font-size: 20px;
  padding: 10px;
  transition: 0.4s all ease-in-out;
}
.pages a:hover{
  font-weight: bold;
}

.checkbtn-label{
  font-size: 28px;
  color: var(--secondary-text);
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  display: none;
  }
  
  
  #check{
  display: none;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    padding: 15px 0;
    margin: 0 auto;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--main);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
    top: 100%;
    left: 0;
    width: 200%;
    min-width: 160px;
    border-radius: 0 0 15px 15px;
    transition: all 0.4s ease-in-out;
    padding-top: 10px;
  }

  .dropdown-content::before{
    content: '';
    position: absolute;
    top: -19px;
    left: 20px;
    border: 10px solid transparent;
    border-bottom-color: var(--main);
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  }
  
.navbar-dropdown {
  position: relative; 
}


.dropdown-content a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  position: relative; 
}

.dropdown:hover .dropdown-content {
  display: block;
}



.dropdown-content a:hover {
  /* color: #e4292a; */
  font-weight: bold;
}

.dropdown-content a:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: auto;
  height: 2px;
  background-color: #e4292a;
  transform: translateX(-50%); 
}

.dropdown i{
  font-size: 14px;
}
.quote-btn1{
  display: none;
}

  @media screen  and (max-width:1150px) {
    .nav-logo p{
      font-size: 16px;
    }
    .pages a{
      font-size: 18px;
    }
    .dropdown-content a {
      font-size: 12px;
    }
  }
  @media screen  and (max-width:1020px) {
    .pages a{
      font-size: 16px;
      padding: 8px;
    }
    .nav-logo img{
      height: 60px;
    }
    .nav-logo{
      font-size: 18px;
    }
    .dropdown-content a {
      font-size: 15px;
    }
    .dropdown-content {
      background-color: var(--secondary-background);
      z-index: 8;
      left: 50%;
      transform: translateX(-50%); 
    }
    .dropdown-content::before{
      top: -19px;
      left: 45%;
    }
  }

  @media (max-width:950px){
    .checkbtn-label{
      display: block;
    }
    .pages{
      position: fixed;
      width: 100%;
      height: 100vh;
      background: var(--main);
      top: 9%;
      left: -100%;
      text-align: center;
      transition: all .5s;
      z-index: 9;
    }
    .pages.open {
      left: 0;
    }
  
    .pages a {
      display: block;
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      color: white;
    }
    .dropdown{
      padding: 0;
      margin-top: -15px;
    }
  }

  @media screen  and (max-width:850px) {
    .pages{
      top: 8%;
    }
  }
  @media screen  and (max-width:500px) {
    .nav-logo{
      font-size: 16px;
    }
  }
  @media screen  and (max-width:400px) {
    .nav-logo{
      font-size: 14px;
    }
    .nav-logo img{
      margin-right: 5px;
    }
    .nav-logo p{
      font-size: 12px;
    }
    .pages{
      top: 6%;
    }
  }
  @media screen  and (max-width:360px) {
    .nav-logo img{
      margin-right: 5px;
    }
    .nav-logo{
      font-size: 12px;
    }
  }

  /* navbar ends here  */

  /* footer starts here  */
  footer{
    background-color: var(--secondary-background);
    padding: 100px 12%;
  }
  
  .footer-up{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .footer-logo img{
    height: 120px;
  }
  .site-map, .socials, .office{
    display: flex;
    flex-direction: column;
  }
  .site-map h4, .socials h4{
    /* font-family: 'Cinzel', serif; */
    font-size: 15px;
    font-weight: 200;
    opacity: 0.9;
    margin-bottom: 10px;
    color: var(--secondary-text);
  }
  .site-map a, .socials a{
    text-decoration: none;
    color: var(--secondary-text);
    font-size: 13px;
    opacity: 0.75;
    font-weight: 200;
    margin: 5px 0;
  }
  .office{
    width: 25%;
  }
  .office h4{
    /* font-family: 'Cinzel', serif; */
    font-size: 15px;
    font-weight: 200;
    opacity: 0.9;
    color: var(--secondary-text);
  }
  
  .office p{
    color: var(--secondary-text);
    font-size: 13px;
    opacity: 0.75;
    font-weight: 200;
    margin: 5px 0;
  }
  #news-letter{
    margin-top: 70px;
  }
  
  form{
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--line);
  }
  form input{
    /* background: none; */
    margin-top: 10px;
    border: none;
    /* color: var(--secondary-text); */
    padding:10px 20px 10px 0px;
  }
  
  form button{
    background: none;
    border: none;
    color: var(--secondary-text);
  }
  form button i{
    font-size: 24px;
    font-weight: 100;
  }
  
  .footer-buttom{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
  }
  
  .footer-buttom-link a{
    text-decoration: none;
    padding-bottom: 10px;
    color: var(--secondary-text);
    opacity: 0.75;
    font-size: 16px;
  }
  .footer-buttom-link{
    padding-bottom: 10px;
    border-bottom: 2px solid var(--line);
  }
  .footer-buttom p{
    opacity: 0.75;
    color: var(--secondary-text);
    font-size: 11px;
  }

  @media screen  and (max-width:950px) {
    .footer-up{
      flex-wrap: wrap;
    }
    .footer-logo{
      flex-basis: 100%;
      margin-bottom: 20px;
    }
    .office{
      width: 35%;
    }
  }
  @media screen  and (max-width:850px) {
   
    .footer-buttom-link{
      padding-bottom: 7px;
    }
    .footer-buttom-link a{
      font-size: 12px;
      
    }
    .footer-buttom p{
      font-size: 10px;
    }
  }
  @media screen  and (max-width:750px) {
    .footer-buttom p{
      width: 30%;
    }
    .office{
      width: 40%;
    }
  }
  @media screen  and (max-width:700px) {
    .footer-buttom p{
      width: 30%;
    }
  }
  @media screen  and (max-width:600px) {
    footer{
      background-color: var(--footer-responsive);
      padding: 30px 12%;
    }
    
    .footer-up{
      flex-direction: column;
    }
    .site-map, .socials{
      padding-bottom: 15px;
      margin-bottom: 15px;
      width: 100%;
      border-bottom: 1px solid var(--line);
    }
     .footer-buttom{
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .form{
      width: 100%;
      justify-content: space-between;
    }
    .footer-buttom-link{
      /* margin-bottom: 20px; */
      border-bottom: none;
    }
    .footer-buttom-link a{
      font-size: 15x;
    }
    .footer-buttom p{
      position: relative;
      margin-top: 50px;
      font-size: 9px;
      width: 100%;
    }
    
    .office{
      width: 100%;
      border-bottom: 1px solid var(--line);
    }
  }

  /* footer ends here  */

  /* home starts here  */

  /* home front with cube starts here  */
  .home{
    display: flex;
    margin-top: 50px;
    padding-left: 9.5%;
    flex-wrap: nowrap;
  }
  .home-content{
    width: 95%;
    z-index: 2;
  }
  .home-content h2{
    font-family: "Ubuntu", sans-serif;
    color: var(--main-text);
    font-size: 70px;
    font-weight: 500;
    margin-top: 70px;
  }
  .home-content span{
    color: var(--main);
  }
  
  .home-content p{
    color: var(--tertiary-text);
    margin: 30px 0;
    opacity: 0.75;
    font-size: 21px;
    width: 80%;
    
  }
  
  .home-content button{
    padding: 15px 25px;
    border: none;
    border-radius: 15px;
    background-color: var(--main);
    transition: all 0.4s ease-in-out;
  }
  .home-content button a{
    text-decoration: none;
    color: #ffffff;
    font-size: 20px;
    transition: all 0.4s ease-in-out;
  }
  .home-content button:hover{
    box-shadow: 0px 2px 4px rgba(3, 0, 59, 0.7);
  }
  .home-content button:hover a{
    font-weight: bold;
  }

  .home-cubes img{
    width: 500px;
    margin-right: 70px;
  }

  @media screen  and (max-width:1480px) {
    .home-content h2{
      font-size: 60px;
      margin-top: 60px;
    }
  }
  @media screen  and (max-width:1360px) {
    .home-content h2{
      font-size: 50px;
      margin-top: 50px;
    }
    .home-content p{
      font-size: 20px;      
    }
  }
  @media screen  and (max-width:1240px) {
    .home-cubes img{
      width: 450px;
    }
  }
  @media screen  and (max-width:1180px) {
    .home-cubes img{
      width: 400px;
    }
  }
  @media screen  and (max-width:1130px) {
    .home-content h2{
      font-size: 45px;
      margin-top: 45px;
    }
    .home-content p{
      font-size: 18px;      
    }
  }
  @media screen  and (max-width:1060px) {
    .home-content h2{
      font-size: 40px;
      margin-top: 40px;
    }
    .home-cubes img{
      width: 380px;
    }
    .home-content p{
      font-size: 16px;      
    }
  }

  @media screen  and (max-width:980px) {
    .home{
      flex-direction: column-reverse;
      padding: 40px 9.5%;
      margin-top: 0;
    }
    .home-cubes{
      display: flex;
      justify-content: center;
    }
    .home-cubes img{
      width: 500px;
    }
    .home-content h2{
      font-size: 70px;
      font-weight: 400;
      margin-top: 20px;
    }
    .home-content p{
      font-size: 20px;
      width: 90%;
    }
    .home-content button a{
      font-size: 19px;
    }
  }
  @media screen  and (max-width:970px) {
    
    .home-cubes img{
      width: 450px;
      margin-right: 0;
    }
    .home-content h2{
      font-size: 60px;
    }
    .home-content p{
      font-size: 18px;
    }
    .home-content button a{
      font-size: 17px;
    }
  }

  @media screen  and (max-width:835px) {
    
    .home-cubes img{
      width: 400px;
    }
    .home-content h2{
      font-size: 50px;
      margin-top: 10px;
    }
    .home-content p{
      font-size: 16px;
    }
    .home-content button a{
      font-size: 15px;
    }
    .home-content button{
      padding: 10px 15px;
      border-radius: 10px;
    }
  }
  @media screen  and (max-width:695px) {
    
    .home-cubes img{
      width: 350px;
    }
    .home-content h2{
      font-size: 40px;
      margin-top: 0px;
    }
  }
  @media screen  and (max-width:560px) {
    .home{
      margin-top: 0;
      padding-top: 20px;
    }
    .home-cubes img{
      width: 300px;
    }
    .home-content h2{
      font-size: 35px;
      margin-top: 0px;
    }
    .home-content p{
      font-size: 14px;
      margin: 15px 0;
    }
    .home-content button a{
      font-size: 13px;
    }
  }
  @media screen  and (max-width:490px) {
    .home-cubes img{
      width: 250px;
    }
    .home-content h2{
      font-size: 30px;
      margin-top: 0px;
    }
    .home-content p{
      width: 100%;
      margin: 15px 0;
    }
    .home-content button a{
      font-size: 12px;
    }
    .home-content button{
      padding: 8px 12px;
      border-radius: 8px;
    }
  }
  @media screen  and (max-width:420px) {
    .home-cubes img{
      width: 200px;
    }
    .home-content h2{
      font-size: 25px;
      margin-top: 0px;
    }
    .home-content p{
      width: 100%;
      margin: 15px 0;
    }
    .home-content button a{
      font-size: 12px;
    }
    .home-content button{
      padding: 8px 12px;
      border-radius: 8px;
    }
  }
  @media screen  and (max-width:350px) {
    .home-cubes img{
      width: 180px;
    }
    .home-content h2{
      font-size: 22px;
      margin-top: 20px;
    }
  }

/* home front ends here  */


/* home about starts here */
#home-about-img{
  transition: 0.4s all ease-in-out;
}
#home-about-img:hover{
  transform: scale(1.06);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.6);
}

.home-about-text h5{
  color: var(--main) !important;
}
.home-about-text h1{
  font-size: 50px;
  color: var(--primary-text) !important;
}

@media screen  and (max-width:768px) {
  .home-about-text h1{
    font-size: 40px;
  }
}
@media screen  and (max-width:400px) {
  .home-about-text h1{
    font-size: 30px;
  }
}

.home-about-icon {
  transition: all 0.4s ease-in-out;
}
.home-about-icon i{
  color: var(--main) !important;
  transition: all 0.4s ease-in-out;
}
.text-primary{
  color: var(--main) !important;
  transition: all 0.4s ease-in-out;
}

/* .home-about-icon:hover{
  background-color: var(--main) !important;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.6);
} */
.home-about-icon:hover i{
  color: white !important;
}
.home-about-icon:hover .text-primary{
  color: white !important;
}
.home-about-icon h6{
  transition: all 0.4s ease-in-out;
}
.home-about-icon:hover h6{
  color: white !important;
}


/* home about ends here */

/* home courses begins here */
  /* .position-relative .img-fluid img{
    width: 400px;
    height: 250px;
  } */
  .service-item {
    position: relative;
    height: 350px;
    padding: 0 30px;
    transition: .5s;
}

.service-item .service-icon {
    width: 150px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main);
    border-radius: 50%;
    transform: rotate(-14deg);
}

.service-item .service-icon i {
    transform: rotate(15deg);
}

.service-item a.btn {
    position: absolute;
    width: 60px;
    bottom: -48px;
    left: 50%;
    margin-left: -30px;
    opacity: 0;
}

.service-item:hover a.btn {
    bottom: -24px;
    opacity: 1;
}

.price-carousel::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    background: var(--main);
    border-radius: 8px 8px 50% 50%;
    z-index: -1;
}

.price-carousel .owl-nav {
    margin-top: 35px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.price-carousel .owl-nav .owl-prev,
.price-carousel .owl-nav .owl-next{
    position: relative;
    margin: 0 5px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main);
    background: #FFFFFF;
    font-size: 22px;
    border-radius: 45px;
    transition: .5s;
}

.price-carousel .owl-nav .owl-prev:hover,
.price-carousel .owl-nav .owl-next:hover {
    color: var(--secondary-background);
}

/* @media (min-width: 576px) {
  .team-item .row {
      height: 350px;
  }
} */

.team-carousel .owl-nav {
  position: absolute;
  padding: 0 45px;
  width: 100%;
  height: 45px;
  top: calc(50% - 22.5px);
  left: 0;
  display: flex;
  justify-content: space-between;
}

.team-carousel .owl-nav .owl-prev,
.team-carousel .owl-nav .owl-next {
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--main) !important;
  border-radius: 45px;
  font-size: 22px;
  opacity: 0.65;
  transition: .5s;

}

.team-carousel .owl-nav .owl-prev:hover,
.team-carousel .owl-nav .owl-next:hover {
  background: white !important;
  border: .5px solid var(--secondary-background);
  color: var(--main);
}


.testimonial-carousel .owl-dots {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 20px;
    height: 20px;
    background: var(--light);
    border: 2px solid var(--primary);
    border-radius: 20px;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    width: 40px;
    height: 40px;
    background: var(--primary);
}

.testimonial-carousel .owl-item img {
    width: 150px;
    height: 150px;
}

.slick-slide {
  margin: 0 auto;
}

.price-carousel::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  bottom: 0;
  bottom: -75px;
  left: 0;
  background: var(--main);
  border-radius: 8px 8px 50% 50%;
  z-index: -1;
}

.price-carousel .owl-nav .owl-prev,
.price-carousel .owl-nav .owl-next{
    position: relative;
    margin: 0 5px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main);
    background: #FFFFFF;
    font-size: 22px;
    border-radius: 45px;
    transition: .5s;
    z-index: 10 !important;
    display: none;
}

.price-carousel .slick-prev:hover,
.price-carousel .slick-next:hover {
  color: var(--secondary-background);
}

.course-box-overflow{
  padding: 0 !important;
  margin-right: -20px !important;
  object-fit: cover !important;
  width: fit-content !important;
  
}

.courses-button-angle{
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  /* z-index: 5; */
  /* background-color: #8CBEDE; */
}
.courses-button-angle i{
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 6px;
  font-size: 20px;
  color: var(--main);
  z-index: 2;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.courses-button-angle i:hover{
  background-color: var(--secondary-background);
  color: white;
}
.courses-button {
  background-color: var(--main) !important;
  color: white;
  transition: all 0.4s ease-in-out;
}
.courses-button:hover {
  background-color: var(--secondary-background) !important;
  color: white !important;
}
/* home courses ends here */


/* network starts here  */

.network{
  padding: 0 9.5%;
}
.network-title{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.network-text{
  color: var(--secondary-text);
  font-size: 50px;
}
#network-text-back{
  color: var(--main);
  display: inline-block;
  padding: 0px 5px ;
  font-weight: 600;
}
#network-text-italic{
  font-style: italic;
}
.network-arrow i{
  background-color: var(--secondary-text);
  border-radius: 8px;
  color: var(--main);
  padding: 16px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
#network-arrow-left{
  margin-right: 15px;
}
.network-arrow i:hover{
  background-color:var(--main) ;
  color:var(--secondary-text) ;
}

.network-container{
  display: flex;
  margin-top: 40px;
  overflow: hidden;
}
.network-card{
  flex: 0 0 210px;
  height: 210px;
  margin-right: 20px;
  background: var(--secondary-text);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.6);
}

.network-card img {
  max-width: 100%;
  max-height: 100%;
}

@media screen  and (max-width:1680px) {
  .network-card{
    flex: 0 0 200px;
    height: 200px;
  }
}
@media screen  and (max-width:1600px) {
  .network-card{
    flex: 0 0 190px;
    height: 190px;
  }
}
@media screen  and (max-width:1520px) {
  .network-card{
    flex: 0 0 175px;
    height: 175px;
  }
}
@media screen  and (max-width:1400px) {
  .network-card{
    flex: 0 0 200px;
    height: 200px;
  }
}
@media screen  and (max-width:1300px) {
  .network-card{
    flex: 0 0 190px;
    height: 190px;
  }
}
@media screen  and (max-width:1300px) {
  .network-card{
    flex: 0 0 180px;
    height: 180px;
  }
}
@media screen  and (max-width:1180px) {
  .network{
    margin-top: 40px;
  }
  .network-card{
    flex: 0 0 170px;
    height: 170px;
  }
}
@media screen  and (max-width:1120px) {
  .network-card{
    flex: 0 0 160px;
    height: 160px;
  }
}
@media screen  and (max-width:1080px) {
  .network-card{
    flex: 0 0 150px;
    height: 150px;
  }
}
@media screen  and (max-width:820px) {
  .network-card{
    flex: 0 0 140px;
    height: 140px;
  }
  .network-text{
    font-size: 40px;
  }
  .network-arrow i{
    border-radius: 8px;
    padding: 12px;
  }
  #network-arrow-left{
    margin-right: 10px;
  }
}
@media screen  and (max-width:760px) {
  .network-card{
    flex: 0 0 120px;
    height: 120px;
    margin-right: 10px;
  }
}
@media screen  and (max-width:620px) {
  .network-card{
    flex: 0 0 100px;
    height: 100px;
  }
  .network-text{
    font-size: 30px;
  }
  .network-arrow i{
    border-radius: 6px;
    padding: 10px;
  }
  #network-arrow-left{
    margin-right: 6px;
  }
}
@media screen  and (max-width:450px) {
  .network-text{
    font-size: 25px;
  }
  .network-arrow i{
    border-radius: 5px;
    padding: 8px;
    font-size: 12px;
  }
  #network-arrow-left{
    margin-right: 5px;
  }
}
@media screen  and (max-width:400px) {
  .network-card{
    flex: 0 0 80px;
    height: 80px;
  }
}

@media screen  and (max-width:350px) {
  .network-text{
    font-size: 20px;
  }
  .network-arrow i{
    border-radius: 5px;
    padding: 8px;
    font-size: 10px;
  }
  #network-arrow-left{
    margin-right: 4px;
  }
}

/* network ends here  */



/* objective start here  */

.objectives{
  width: 60%;
  margin: 50px auto;
}
.objectives h3{
  font-family: "Ubuntu", sans-serif;
  color: var(--secondary-text);
  font-size: 60px;
  font-weight: 400;
  margin: 50px auto;
  text-align: center;
}
.objectives span{
  color: var(--main);
}
.objective-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s ease-in-out;
}
.objective-container:hover{
  transform: scale(1.05);
}

.objective-image img{
  /* width: 500px; */
  height: 500px;
}

.objective-text{
  width: 35%;
}
.objective-text h2{
  color: var(--primary-text);
  font-size: 35px;
}
.objective-text p{
  color: var(--secondary-text);
  font-size: 18px;
  padding-top: 50px;
}

@media screen  and (max-width:1440px) {
  .objective-image img{
    /* width: 450px; */
    height: 450px;
  }
}
@media screen  and (max-width:1300px) {
  .objectives{
    width: 65%;
    margin: 50px auto;
  }
}
@media screen  and (max-width:1200px) {
  .objectives{
    width: 70%;
    /* margin: 50px auto; */
  }
}
@media screen  and (max-width:1180px) {
  .objective-image img{
    /* width: 400px; */
    height: 400px;
  }
}
@media screen  and (max-width:1000px) {
  .objectives{
    width: 75%;
  }
}
@media screen  and (max-width:900px) {
  .objective-container{
    flex-direction: column-reverse;
    align-items:flex-start ;
  }
  .objective-reversed{
    flex-direction: column;
  }
  .objective-text{
    width: 100%;
  }
  .objective-text p{
    padding: 30px 0;
  }
  .objective-image img{
    margin-bottom: 40px;
  }
  .objectives h3{
    font-size: 55px;
  }
}
@media screen  and (max-width:800px) {
  .objective-image img{
    /* width: 350px; */
    height: 350px;
  }
  .objectives h3{
    font-size: 50px;
  }
}
@media screen  and (max-width:600px) {
  .objective-image img{
    /* width: 300px; */
    height: 300px;
  }
  .objective-text h2{
    font-size: 30px;
  }
  .objective-text p{
    font-size: 15px;
    padding-top: 20px;
  }
  .objectives h3{
    font-size: 45px;
  }
}
@media screen  and (max-width:500px) {
  .objective-image img{
    /* width: 250px; */
    height: 250px;
  }
  .objective-text h2{
    font-size: 25px;
  }
  .objective-text p{
    font-size: 14px;
    padding-top: 20px;
  }
  .objectives h3{
    font-size: 35px;
  }
}
@media screen  and (max-width:425px) {
  .objective-image img{
    /* width: 200px; */
    height: 200px;
  }
  .objectives h3{
    font-size: 25px;
  }
}

/* objective ends here  */


/* home box starts here  */

.home-box{
  width: 88%;
  height: 550px;
  background-color: var(--box-background);
  border: 10px solid var(--box-border);
  margin: 50px auto;
  margin-top: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.home-box-img1 img{
  height: 660px;
  width: auto;
  z-index: 2;
  position: relative;
  top: -42px;
  left: -110px;
}


.home-box-text{
  position: relative;
  /* margin-right: 60px; */
  width: 40%;
}
.home-box-text h2{
  color: var(--secondary-text);
  font-size: 40px;
}
.home-box-text p{
  color: var(--secondary-text);
  font-size: 16px;
  margin: 40px 0;
  font-weight: 300;
}

.home-box-text a{
  background-color: var(--main);
  color: var(--secondary-text);
  font-weight: 500;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.4s ease-in-out;
  font-size: 15px;
}


@media screen  and (max-width:1400px) {
  .home-box-text{
    left: -100px;
    width: 100%;
  }
}
@media screen  and (max-width:1320px) {
  
.home-box{
  height: 500px;
}
.home-box-img1 img{
  height: 600px;
  top: -35px;
  left: -110px;
}
}
@media screen  and (max-width:1250px) {
  /* .home-box-text{

  } */
  .home-box-text h2{
    font-size: 35px;
  }
  
}
@media screen  and (max-width:1180px) {
  .home-box-text h2{
    font-size: 30px;
  }
  
.home-box{
  height: 450px;
}
.home-box-img1 img{
  height: 550px;
  top: -35px;
  left: -110px;
  overflow: hidden;
  /* width: 80%; */
}
}
@media screen  and (max-width:1060px) {
  .home-box-text h2{
    font-size: 30px;
  }
  
.home-box{
  height: 420px;
}
.home-box-img1 img{
  height: 500px;
  top: -30px;
  left: -110px;
}
}
@media screen  and (max-width:1000px) {
  .home-box{
    flex-direction: column;
  }
  /* .home-box-text h2{
    font-size: 30px;
  } */
  
.home-box{
  height: 750px;
}
.home-box-img1 img{
  height: 600px;
  margin: 0 auto;
  top: -100px;
  left: 0px;
}
.home-box-text{
  text-align: left;
  left: 0;
  width: 90%;
  top: -80px;
  
}
}
@media screen  and (max-width:850px) {
  .home-box-img1 img{
    height: 580px;
  }
  .home-box{
    overflow-x: clip;
  }
}
@media screen  and (max-width:600px) {
  .home-box{
    width: 100%;
  }
  .home-box-img1 img{
    left: -50px;
  }
}
@media screen  and (max-width:500px) {
  .home-box{
    height: 700px;
  }
  .home-box-img1 img{
    height: 500px;
    left: -50px;
  }
  .home-box-text h2{
    font-size: 30px;
  }
}
@media screen  and (max-width:420px) {
  .home-box{
    height: 620px;
  }
  .home-box-img1 img{
    height: 450px;
    left: -50px;
  }
  .home-box-text h2{
    font-size: 25px;
  }
  .home-box-text p{
    font-size: 14px;
  }
}
@media screen  and (max-width:350px) {
  .home-box-text h2{
    font-size: 22px;
  }
  .home-box-text p{
    font-size: 14px;
  }
  .home-box-text a{
    font-size: 13px;
    padding: 8px 15px;
  }
}

/* home box ends here  */


/* home help starts here  */
.home-help{
  margin: 90px 9.5% ;
  align-items: center;
}
.home-help h2{
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  color: var(--secondary-text);
}
.home-help span{
  background-color: var(--primary-text-back);
  padding: 0 5px;
}

.home-help-box{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}
.help-box{
  flex-basis: 33.3%;
  align-items: center;
  text-align: center;
  padding: 20px;
  color: var(--secondary-text);
  transition: 0.4s all ease-in-out;
}

.help-box img{
  margin: 30px 0;
}
.help-box h3{
  color: var(--main);
}

.help-box p{
  margin: 30px 0;
  color: var(--tertiary-text);
  font-size: 15px;
  transition: 0.4s all ease-in-out; 
}
.help-box:hover{
  background-color: var(--secondary-text);
  color: var(--main);
  box-shadow: 0px 2px 4px rgba(3, 0, 59, 0.7);
}
.help-box:hover p{
  opacity:  1 !important;
  color: var(--primary-background);
  opacity: 0.9;
}
.box1{
  border-right: 1px solid var(--line);
  border-bottom: 1px solid var(--line);
}
.box2{
  border-right: 1px solid var(--line);
  border-bottom: 1px solid var(--line);
}
.box3{
  border-bottom: 1px solid var(--line);
}
.box5{
  border-left: 1px solid var(--line);
  border-right: 1px solid var(--line);
}

@media screen  and (max-width:1240px) {
  .home-help h2{
    font-size: 45px;
  }
  .help-box h3{
    font-size: 18px;
  }
  .help-box p{
    font-size: 14px;
  }
}
@media screen  and (max-width:1000px) {
  .help-box{
    flex-basis: 50%;
  }
  .box2{
    border-right: none;
    border-bottom: 1px solid var(--line);
  }
  .box3{
    border-right: 1px solid var(--line);
    border-bottom: 1px solid var(--line);
  }
  .box4{
    border-bottom: 1px solid var(--line);
  }
  .box5{
    border-left: none;
  }
}

@media screen  and (max-width:800px) {
  .home-help h2{
    font-size: 35px;
  }
}
@media screen  and (max-width:600px) {
  .home-help h2{
    font-size: 30px;
    text-align: left;
  }
  .help-box{
    flex-basis: 100%;
  }
  .box1{
    border-right: none;
  }
  .box3{
    border-right: none;
  }
  .box4{
    border-bottom: 1px solid var(--line);
  }
  .box5{
    border-right: none;
    border-bottom: 1px solid var(--line);
  }
}




/* home ends here  */
/* appointment starts here  */
#appointment-container{
  background-color: var(--main) !important;
}
#appointment-button{
  background-color: var(--main) !important;
  transition: all 0.4s ease-in-out;
}
#appointment-button:hover{
  background-color: var(--primary-text) !important;
  box-shadow: 0px 2px 4px rgba(7, 0, 31, 0.5);
}

/* appointment ends here */

/* blog starts here  */
.home-blog h1{
  color: var(--main) !important;
}
.home-blog a:hover{
  color: var(--main) !important;
}

/* About starts here  */

/* gallery starts here  */


/* ::selection{
  color: #fff;
  background: #007bff;
}
body{
  padding: 10px;
} */
.wrapper{
  margin: 10px auto;
  max-height: max-content;
  height: 50vh;
  max-width: 1100px;
}
.wrapper nav{
  display: flex;
  justify-content: center;
}
.wrapper .items{
  display: flex;
  max-width: 720px;
  width: 100%;
  justify-content: space-between;
}
.items span{
  padding: 7px 25px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  color: #007bff;
  border-radius: 50px;
  border: 2px solid #007bff;
  transition: all 0.3s ease;
}
.items span.active,
.items span:hover{
  color: #fff;
  background: #007bff;
}

.gallery{
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  height: 60px;
}
.gallery .image{
  width: calc(100% / 4);
  padding: 7px;
}
.gallery .image span{
  display: flex;
  width: 100%;
  overflow: hidden;
}
.gallery .image img{
  width: 100%;
  vertical-align: middle;
  transition: all 0.3s ease;
}
.gallery .image:hover img{
  transform: scale(1.1);
}
.gallery .image.hide{
  display: none;
}
.gallery .image.show{
  animation: animate 0.4s ease;
}
@keyframes animate {
  0%{
    transform: scale(0.5);
  }
  100%{
    transform: scale(1);
  }
}

.preview-box{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  background: #fff;
  max-width: 700px;
  width: 100%;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  border-radius: 3px;
  padding: 0 5px 5px 5px;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
}
.preview-box.show{
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
  transition: all 0.3s ease;
}
.preview-box .details{
  padding: 13px 15px 13px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.details .title{
  display: flex;
  font-size: 18px;
  font-weight: 400;
}
.details .title p{
  font-weight: 500;
  margin-left: 5px;
}
.details .icon{
  color: #007bff;
  font-style: 22px;
  cursor: pointer;
}
.preview-box .image-box{
  width: 100%;
  display: flex;
}
.image-box img{
  width: 100%;
  border-radius: 0 0 3px 3px;
}
.shadow{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: none;
  background: rgba(0,0,0,0.4);
}
.shadow.show{
  display: block;
}

@media (max-width: 1000px) {
  .gallery .image{
    width: calc(100% / 3);
  }
}
@media (max-width: 800px) {
  .gallery .image{
    width: calc(100% / 2);
  }
}
@media (max-width: 700px) {
  .wrapper nav .items{
    max-width: 600px;
  }
  nav .items span{
    padding: 7px 15px;
  }
}
@media (max-width: 600px) {
  .wrapper{
    margin: 30px auto;
  }
  .wrapper nav .items{
    flex-wrap: wrap;
    justify-content: center;
  }
  nav .items span{
    margin: 5px;
  }
  .gallery .image{
    width: 100%;
  }
}

/* about top starts here  */

.about-icon{
  display: flex;
  padding: 20px;
  justify-content: center;
  color: var(--secondary-text);
  opacity: 0.75;
  font-size: 12px;
}

.about-icon i{
  margin-right: 10px;
}


.about-top-text{
  color: var(--secondary-text);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  margin: 40px auto;
}


/* contact starts here  */

.contact-details{
  display: flex;
  margin: 50px auto;
  width: 70%;
  justify-content: center;

}

.contact-links{
  background:var(--secondary-background);
  padding: 15px;
  border-radius: 6px;
  border: 2px solid var(--ui_primary);
  text-align: center;
  width: 30%;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(7, 0, 31, 0.8);
  cursor: pointer;
  margin: 0 20px;
  transition: all 0.4s ease-in-out;
  text-decoration: none;
}
.contact-links p{
  color: var(--main);
}
.contact-container p{
  padding: 5px;
  transition: all 0.4s ease-in-out;
}
.contact-container p i{
  color: var(--ui_primary);
  transition: all 0.4s ease-in-out;
}
.text-color{
  color: var(--ui_primary);
  transition: all 0.4s ease-in-out;
}
.contact-links:hover{
  background: var(--main);
  color: #fff; 
  box-shadow: 0px 2px 4px rgba(12, 0, 66, 0.7);
}
.contact-links:hover p i{
  color: #fff;
}
.contact-links:hover p{
  color: #fff;
}

.contact-map-form{
  width: 100%;
  display: flex;
  margin: 0 auto;
  margin-bottom: 75px;
}
.contact-map{
  width: 100%;
}
.contact-map iframe{
  width: 100%;
  height: 100%;
}
.contact-form{
  background-color: var(--secondary-background);
  padding: 25px 40px;
  width: 100%;
}

.contact-form h2{
  color: var(--secondary-text);
  margin-bottom: 20px;
}

.contact-form-content{
  width: 95%;
}
.contact-form-content label{
  display: inline-block;
  width: 100%;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 10px;
  color: var(--secondary-text);
}

.contact-form-content input{
  padding: 10px;
  /* background: var(--secondary-text);
  color: var(--primary-background); */
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.contact-form-content button{
  margin: 10px 0;
  padding: 15px 45px;
  /* width: 30%; */
  cursor: pointer;
  border-radius: 6px;
  font-weight: bold;
  text-transform: uppercase;background-color: var(--main);
  color: var(--secondary-text);
  transition: all 0.4s ease-in-out;
}

.contact-form-content button:hover{
  background: var(--secondary-text);
  color: var(--main);
  box-shadow: 0px 2px 4px rgba(4, 0, 50, 0.7);
}

@media screen and (max-width: 800px) {
  .contact-details{
    flex-direction: column;
  }
  .contact-links{
    width: 100%;
    margin-bottom: 20px;
  }
  .contact-map-form{
    flex-direction: column-reverse;
  }
  .contact-map{
    width: 100%;
    height: 400px;
  }

}
@media screen  and (max-width:400px) {
  .contact-form-content button{
    width: 60%;
  }
  .contact-form h2{
    font-size: 20px;
  }
}





/* services starts here  */
.about-services {
  width: 100%;
  display: grid;
  place-items: center;
  padding: 20px 9.5%;
}

.services-row {
  display: flex;
  flex-wrap: wrap;
}

.services-column {
  width: 100%;
  padding: 0 1em 1em 1em;
  text-align: center;
}

.services-card {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  background: linear-gradient(#ffffff 50%, var(--main) 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}

.services-card h3 {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-text);
  margin: 1em 0;
}

.services-card p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
}

.services-icon-wrapper {
  background-color: var(--main);
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}

.services-card:hover {
  background-position: 0 100%;
}

.services-card:hover .services-icon-wrapper {
  background-color: #ffffff;
  color: var(--main);
}

.services-card:hover h3 {
  color: #ffffff;
}

.services-card:hover p {
  color: #f0f0f0;
}

@media screen and (min-width: 768px) {
  .section {
    padding: 0 2em;
  }
  
  .services-column {
    flex: 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .section {
    padding: 1em 3em;
  }
  
  .services-column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}



.swiper-slide--one {
  background: linear-gradient(to top, #0f2027, #203a4300, #2c536400), url(../public/assets/images/about1.jpg);
  background-size: cover;
}

.swiper-slide--two {
  background: linear-gradient(to top, #0f2027, #203a4300, #2c536400), url(../public/assets/images/about1.jpg);
  background-size: cover;
}
.swiper-slide--three {
  background: linear-gradient(to top, #0f2027, #203a4300, #2c536400), url(../public/assets/images/about1.jpg);
  background-size: cover;
}
.swiper-slide--four {
  background: linear-gradient(to top, #0f2027, #203a4300, #2c536400), url(../public/assets/images/about1.jpg);
  background-size: cover;
}
.swiper-slide--five {
  background: linear-gradient(to top, #0f2027, #203a4300, #2c536400), url(../public/assets/images/about1.jpg);
  background-size: cover;
}


.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}



.home-gallery {
  margin: 0 auto; /* Center the gallery if needed */
  padding: 50px 0; /* Add padding to top and bottom */
  /* background-color: #000000; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Stack items vertically */
}

.carousel {
  width: 100%;
  max-width: 95vw;
  height: 65vh;
  perspective: 1000px;
  overflow: hidden !important; /* Keep !important if necessary to override */
}

/* Ensure there's enough space at the bottom of the .home-gallery */
.home-gallery::after {
  content: "";
  display: block;
  height: 50px; /* Adjust based on required spacing */
}



.carousel-content {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  animation: carousel 20s infinite linear; /* Ensure this matches the keyframes name */
}

.carousel-items {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 1; /* Ensure items are visible */
}

/* Adjusted transformations for a circular layout */
.carousel-items:nth-child(1) { transform: rotateY(0deg) translateZ(500px); }
.carousel-items:nth-child(2) { transform: rotateY(-60deg) translateZ(500px); }
.carousel-items:nth-child(3) { transform: rotateY(-120deg) translateZ(500px); }
.carousel-items:nth-child(4) { transform: rotateY(-180deg) translateZ(500px); }
.carousel-items:nth-child(5) { transform: rotateY(-240deg) translateZ(500px); }
.carousel-items:nth-child(6) { transform: rotateY(-300deg) translateZ(500px); }

@keyframes carousel {
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(-360deg); }
}




.container{
  margin: 0 auto;
  padding: 50px 0;
  width: 100%;
  max-width: 90%;
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between; 
}
.front-face {
  width: 100%;
  max-width: 90vw;
  height: 100vh;
  perspective: 1000px;
  overflow: hidden !important; 
}

.center,.front-face,
.contents,.back-face{
  position: absolute;
}
.center {
  width: calc(100% / 3 - 20px); 
  position: relative;
  height: 400px;
  perspective: 1000px;
  perspective-origin: center;
}
.front-face, .back-face{
  height: 100%;
  width: 100%;
  text-align: center;
  background: linear-gradient(rgba(0,0,0,.2),
              rgba(0,0,0,.2)),url(../public/assets/images/about1.jpg);
  background-size: cover;
  background-position: center;
  transform: translateY(0deg);
  border-radius: 10px;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: transform .7s cubic-bezier(.4,.2,.2,1);
}
.contents{
  left: 0%;
  top: 50%;
  width: 100%;
  perspective: 1000px;
  transform: translateY(-50%) translateZ(60px) scale(0.94);
}
.front p{
  font-size: 35px;
  margin-bottom: 15px;
  color: white;
}
.front span{
  font-size: 23px;
  color: white;
}
.front p:after{
  content: '';
  display: block;
  left: 0;
  right: 0;
  width: 100px;
  height: 2px;
  background: white;
  margin: 0 auto;
  margin-top: 10px;
}
.back-face{
  transform: rotateY(180deg);
  background: linear-gradient(45deg,#043348 0%,#032535 100%);
}
.back {
  color: white;
}
.back h2{
  font-size: 33px;
  padding-bottom: 5px;
}
.back span{
  font-size: 25px;
}
.icons{
  margin: 10px 0;
  display: block;
}
i.fab{
  color:  #042f4b;
  font-size: 20px;
  height: 40px;
  width: 40px;
  background: white;
  border-radius: 50%;
  margin: 0 5px;
  line-height: 40px;
  cursor: pointer;
}
.center:hover > .back-face{
  transform: rotateY(0deg);
}
.center:hover > .front-face{
  transform: rotateY(-180deg);
}

.back-face h2{
  display: none;
  /* transition: all 0.3s ease-in-out; */
}
.back-face:hover h2{
  display: block;
}
.back-face p{
  display: none;
  /* transition: all 0.3s ease-in-out; */
}
.back-face:hover p{
  display: block;
}


.back-face {
  transform: rotateY(180deg);
  background: linear-gradient(45deg, #01124f 0%, #060186 100%);
  display: block; /* Display the back-face by default */
}

/* Hide the back-face content by default */
.back-face h2, .back-face p {
  display: none;
}

/* Show the back-face content when hovered */
.center:hover > .back-face h2, .center:hover > .back-face p {
  display: block;
}

@media screen and (max-width: 1000px) {
  .center{
    flex-basis: 48%;
    margin-top: 40px;
  }
}
@media screen and (max-width: 550px) {
  .center{
    flex-basis: 95%;
    /* margin-top: 40px; */
  }
}








#blog-text-color{
  color: var(--primary-text);
}

.blog-courses a:hover{
  color: var(--primary-text) !important;
}

#comment-button{
  background-color: var(--main);
  transition: all 0.4s ease-in-out;
}
#comment-button:hover{
  background-color: var(--secondary-background);
  color: var(--primary-text);
  font-weight: bold;
}



.btn-primary{
  background-color: var(--main);
  border: var(--secondary-background);
}


.courses-details-text{
  color: var(--primary-text);
}
.courses-details-text i:hover{
  color: var(--primary-text);
}

#enrollment-button{
  transition: all 0.4s ease-in-out;
}
#enrollment-button:hover{
  background-color: var(--secondary-background);
  color: var(--secondary-text);
  box-shadow: 0px 2px 4px rgba(38, 38, 39, 0.3);
}

.details-link:hover{
  color: var(--main)  !important;
}


.cmain {
  width: 100%;
  height: auto;
  display: grid;
  place-items: center;
  padding: 50px 0;
}

.chead {
  padding: 50px;
}

.chead::after {
  content: " ";
  position: absolute;
  width: 50%;
  height: 3px;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  background-image: linear-gradient(to right, rgba(34, 52, 249, 0.767), rgba(3, 2, 74, 0.747));
}

.ccontainer {
  width: 70%;
  height: auto;
  margin: auto 0;
  position: relative;
}

.clist {
  list-style: none;
}

.clist::after {
  content: " ";
  position: absolute;
  width: 2px;
  height: 100%;
  left: 51.5%;
  top: 0;
  transform: translateX(-50%);
  background-image: linear-gradient(to right, rgba(34, 52, 249, 0.767), rgba(3, 2, 74, 0.747));
}

.citem {
  width: 50%;
  height: auto;
  padding: 15px 20px;
  /* background-color: #fff; */
  border-radius: 8px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.218);
  position: relative;
  margin-bottom: 30px;
  z-index: 2;
  transition: all 0.4s ease-in-out;
}

.citem1 {
  width: 90%;
  height: auto;
  padding: 15px 20px;
  /* background-color: #fff; */
  border-radius: 8px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.218);
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px;
  z-index: 2;
  transition: all 0.4s ease-in-out;
}

.citem1:hover{
  background-color: var(--secondary-text);
}
.citem1 h3{
  transition: all 0.4s ease-in-out;
}
.citem1:hover h3{
  color: var(--primary-text);
}
.citem:hover{
  background-color: var(--secondary-text);
}
.citem h3{
  transition: all 0.4s ease-in-out;
}
.citem:hover h3{
  color: var(--primary-text);
}

.citem:nth-child(4) {
  margin-bottom: 0;
}

.ccircle {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--main);
  top: 0;
  display: grid;
  place-items: center;
}

.ccircle::after {
  content: ' ';
  width: 12px;
  height: 12px;
  background-color: var(--secondary-background);
  border-radius: 50%;
}

.citem:nth-child(odd) .ccircle {
  transform: translate(50%, -50%);
  right: -30px;
}

.citem:nth-child(even) .ccircle {
  transform: translate(-50%, -50%);
  left: -30px;
}

.cdate {
  position: absolute;
  width: 130px;
  height: 33px;
  background-image: linear-gradient(to right,rgba(34, 52, 249, 0.767),rgba(3, 2, 74, 0.747));
  border-radius: 15px;
  top: -45px;
  display: grid;
  place-items: center;
  color: #fff;
  font-size: 13px;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.318);
}

.cdate1{
  display: inline-block;
  background-image: linear-gradient(to right,rgba(34, 52, 249, 0.767),rgba(3, 2, 74, 0.747));
  border-radius: 15px;
}

.citem:nth-child(odd) {
  float: left;
  clear: right;
  text-align: right;
  transform: translateX(-30px);
}

.citem:nth-child(odd) .cdate {
  right: 20px;
}

.citem:nth-child(even) {
  float: right;
  clear: left;
  transform: translateX(30px);
}

.cheading {
  font-size: 17px;
  color: var(--secondary-text);
}

.citem p {
  font-size: 13px;
  color: #666;
  line-height: 18px;
  margin: 6px 0 4px 0;
}

.citem a {
  font-size: 13px;
  text-decoration: none;
  color: var(--main);
  transition: all 0.3s ease;
}

@media only screen and (min-width: 798px) and (max-width: 1100px) {
  .ccontainer {
      width: 80%;
  }
}

@media only screen and (max-width: 798px) {
  .ccontainer {
      width: 70%;
      transform: translateX(20px);
  }

  .clist::after {
      left: 7px;
  }

  .citem {
      width: 100%;
      float: none;
      clear: none;
      margin-bottom: 80px;
  }

  .citem .ccircle {
      left: -40px;
      transform: translate(-50%, -50%);
  }

  .citem .cdate {
      left: 20px;
  }

  .citem:nth-child(odd) {
      transform: translateX(0px);
      text-align: left;
  }

  .citem:nth-child(even) {
      transform: translateX(0px);
  }
}

@media only screen and (max-width: 550px) {
  .ccontainer {
      width: 80%;
  }

  .clist::after {
      left: -0px;
  }

  .citem .ccircle {
      left: -50px;
  }
}



@media screen  and (max-width:520px) {
  .table_resize{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen  and (max-width:400px) {
  .search-row{
    flex-direction: column !important;
  }

  .search-row select{
    margin-bottom: 10px;
  }
  .page_margin{
    position: absolute;
    /* z-index: -1; */
    left: 0;
    width: 100%;

  }
  .space-only{
    height: 100px;
  }
}


@media screen and (min-width: 1024px) {
  .review-container {
    width: 30%;
  }
}

/* Medium screens - 2 review containers */
@media screen and (max-width: 1023px) {
  .review-container {
    width: 45%;
  }
}

/* Small screens - 1 review container */
@media screen and (max-width: 767px) {
  .review-container {
    width: 90%;
  }
}

.owl-nav{
  display: none;
}